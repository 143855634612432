import baseAPI from 'api/baseAPI';

export const sendVerificationCode = async (email) => {
  try {
    const resp = await baseAPI.post('/verification/send-otp/email', {
      email,
    });

    if (!resp.ok) {
      throw new Error('Failed to send verification email');
    }

    const data = await resp.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const verifyVerificationCode = async (email, code) => {
  try {
    const resp = await baseAPI.post('/verification/verify-otp/email', {
      email,
      code,
    });

    if (!resp.ok) {
      throw new Error('Failed to verify email');
    }

    const data = await resp.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
