import baseAPI from 'api/baseAPI';

export const sendVerificationCode = async (phoneNumber, countryCode) => {
  try {
    const resp = await baseAPI.post('/verification/send-otp/whatsapp', {
      phoneNumber,
      countryCode,
    });

    if (!resp.ok) {
      throw new Error('Failed to send verification code');
    }

    const data = await resp.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const verifyVerificationCode = async (
  phoneNumber,
  countryCode,
  code
) => {
  try {
    const resp = await baseAPI.post('/verification/verify-otp/whatsapp', {
      phoneNumber,
      countryCode,
      code,
    });

    if (!resp.ok) {
      throw new Error('Failed to verify code');
    }

    const data = await resp.json();

    if (!data.success) {
      throw new Error(data.message);
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
