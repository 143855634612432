import { monthNames } from 'hire/apply/constants';
import { useEffect } from 'react';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import useApplyFormStore from 'state/apply-form';

export default function WorkExperience({ form }) {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = form;

  const { formData } = useApplyFormStore();

  const experience = useWatch({
    control,
    name: 'workExperience.experience',
  });

  const employerFields = useWatch({
    control,
    name: 'workExperience.pastEmployers',
  });

  useEffect(() => {
    if (experience > 0 && (!employerFields || employerFields.length === 0)) {
      toast.error(
        'At least one past employer is required when you have work experience'
      );

      setValue('workExperience.pastEmployers', [
        {
          employerName: '',
          employerUrl: '',
          startMonth: '',
          startYear: '',
          endMonth: '',
          endYear: '',
        },
      ]);
    }
  }, [experience, employerFields, setValue]);

  const removeEmployer = (index) => {
    const newEmployers = employerFields.filter((_, i) => i !== index);
    setValue('workExperience.pastEmployers', newEmployers);
  };

  const appendEmployer = () => {
    const newEmployers = [
      ...(employerFields ?? []),
      {
        employerName: '',
        employerUrl: '',
        startMonth: '',
        startYear: '',
      },
    ];

    setValue('workExperience.pastEmployers', newEmployers);
  };

  return (
    <div className="self-stretch px-10 py-4 flex-col justify-center items-start gap-10 flex">
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Upload your resume
        </label>

        <small className="text-neutral-500">
          Your file should be a PDF or Word Document, no larger than 2MB
        </small>

        <Controller
          name="workExperience.resume"
          control={control}
          rules={{
            required: 'Resume is required.',
            validate: (value) => {
              if (value && value[0]?.size > 2 * 1024 * 1024) {
                return 'File size should be 2 MB or less';
              }
              return true;
            },
          }}
          render={({ field: { onChange, value, ...field } }) => (
            <div className="w-full">
              <input
                type="file"
                {...field}
                className="hidden"
                id="resume-upload"
                accept=".pdf,.doc,.docx"
                onChange={(e) => {
                  onChange(e.target.files);
                }}
              />
              <label
                htmlFor="resume-upload"
                className="flex items-center justify-between w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 cursor-pointer"
              >
                <span className="text-gray-500">
                  {value && value[0]
                    ? value[0].name
                    : formData.workExperience?.resume?.[0]?.name ||
                      'Choose file'}
                </span>
                <span className="text-[#085454] font-medium">Browse</span>
              </label>
            </div>
          )}
        />
        {errors.workExperience?.resume && (
          <p className="text-red-600 text-sm">
            {errors.workExperience.resume.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          How soon can you begin work?
        </label>
        <Controller
          name="workExperience.beginWork"
          control={control}
          rules={{ required: 'This field is required' }}
          defaultValue={formData.workExperience?.beginWork || ''}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              {...rest}
              value={{ label: value, value }}
              options={[
                { label: 'Immediately', value: 'Immediately' },
                { label: '>15 days', value: '>15 days' },
                { label: '<15 days', value: '<15 days' },
                { label: '>30 days', value: '>30 days' },
                { label: '<50 days', value: '<50 days' },
              ]}
              onChange={(selectedOption) => onChange(selectedOption.value)}
              className="w-full self-stretch"
              classNamePrefix="select"
              placeholder="Select when you can begin"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '48px',
                  borderColor: '#262626',
                  borderRadius: '0.5rem',
                }),
              }}
            />
          )}
        />
        {errors.workExperience?.beginWork && (
          <p className="text-red-600 text-sm">
            {errors.workExperience.beginWork.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          How many years of work experience do you have in customer service?
        </label>
        <input
          placeholder="Experience"
          {...register('workExperience.experience', {
            required: 'This field is required',
            onChange: (e) => {
              const value = parseInt(e.target.value);
              if (
                value > 0 &&
                (!employerFields || employerFields.length === 0)
              ) {
                appendEmployer();
              }
            },
          })}
          defaultValue={formData.workExperience?.experience}
          className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
          type="number"
          min={0}
        />
        {errors.workExperience?.experience && (
          <p className="text-red-600 text-sm">
            {errors.workExperience.experience.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          How many years of work experience do you have in remote customer
          service?
        </label>
        <input
          placeholder="Remote Experience"
          {...register('workExperience.remoteExperience', {
            required: 'This field is required',
          })}
          defaultValue={formData.workExperience?.remoteExperience}
          className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
          type="number"
          min={0}
        />
        {errors.workExperience?.remoteExperience && (
          <p className="text-red-600 text-sm">
            {errors.workExperience.remoteExperience.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Past Employers{' '}
        </label>
        {experience > 0 && (
          <p className="text-neutral-600 text-sm">
            {experience > 0 && <span>*</span>} At least one past employer is
            required when you have work experience
          </p>
        )}
        {employerFields?.map((field, index) => (
          <div
            key={field.id}
            className="w-full flex flex-col gap-4 p-4 border border-neutral-300 rounded-lg"
          >
            <div className="flex items-center justify-between">
              <h4 className="text-lg font-semibold">Employer {index + 1}</h4>
              <Trash
                className="text-red-500 cursor-pointer"
                onClick={() => removeEmployer(index)}
              />
            </div>
            <input
              {...register(
                `workExperience.pastEmployers.${index}.employerUrl`,
                {
                  onChange: (e) => {
                    setValue(
                      `workExperience.pastEmployers.${index}.employerUrl`,
                      e.target.value
                    );
                  },
                  required: 'Employer URL is required',
                }
              )}
              placeholder="Employer URL"
              className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
            />
            {errors.workExperience?.pastEmployers?.[index]?.employerUrl && (
              <p className="text-red-600 text-sm">
                {errors.workExperience.pastEmployers[index].employerUrl.message}
              </p>
            )}
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-2">
                <label className="text-sm text-neutral-600">Start Month</label>
                <select
                  {...register(
                    `workExperience.pastEmployers.${index}.startMonth`,
                    {
                      onChange: (e) => {
                        setValue(
                          `workExperience.pastEmployers.${index}.startMonth`,
                          e.target.value
                        );
                      },
                      required: 'Start Month is required',
                    }
                  )}
                  className="w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
                >
                  <option value="">Select Start Month</option>
                  {monthNames.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                {errors.workExperience?.pastEmployers?.[index]?.startMonth && (
                  <p className="text-red-600 text-sm">
                    {
                      errors.workExperience.pastEmployers[index].startMonth
                        .message
                    }
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm text-neutral-600">Start Year</label>
                <select
                  {...register(
                    `workExperience.pastEmployers.${index}.startYear`,
                    {
                      onChange: (e) => {
                        setValue(
                          `workExperience.pastEmployers.${index}.startYear`,
                          e.target.value
                        );
                      },
                      required: 'Start Year is required',
                    }
                  )}
                  className="w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
                >
                  <option value="">Select Start Year</option>
                  {Array.from(
                    { length: 50 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.workExperience?.pastEmployers?.[index]?.startYear && (
                  <p className="text-red-600 text-sm">
                    {
                      errors.workExperience.pastEmployers[index].startYear
                        .message
                    }
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm text-neutral-600">End Month</label>
                <select
                  {...register(
                    `workExperience.pastEmployers.${index}.endMonth`,
                    {
                      onChange: (e) => {
                        setValue(
                          `workExperience.pastEmployers.${index}.endMonth`,
                          e.target.value
                        );
                      },
                      required: 'End Month is required',
                    }
                  )}
                  className="w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
                >
                  <option value="">Select End Month</option>
                  {monthNames.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                {errors.workExperience?.pastEmployers?.[index]?.endMonth && (
                  <p className="text-red-600 text-sm">
                    {
                      errors.workExperience.pastEmployers[index].endMonth
                        .message
                    }
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <label className="text-sm text-neutral-600">End Year</label>
                <select
                  {...register(
                    `workExperience.pastEmployers.${index}.endYear`,
                    {
                      onChange: (e) => {
                        setValue(
                          `workExperience.pastEmployers.${index}.endYear`,
                          e.target.value
                        );
                      },
                      required: 'End Year is required',
                    }
                  )}
                  className="w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
                >
                  <option value="">Select End Year</option>
                  {Array.from(
                    { length: 50 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {errors.workExperience?.pastEmployers?.[index]?.endYear && (
                  <p className="text-red-600 text-sm">
                    {errors.workExperience.pastEmployers[index].endYear.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={() =>
            appendEmployer({
              employerUrl: '',
              startMonth: '',
              startYear: '',
              endMonth: '',
              endYear: '',
            })
          }
          className="flex items-center gap-2 text-[#085454] font-medium"
        >
          <PlusCircle /> Add Past Employer
        </button>
      </div>
    </div>
  );
}
