import {
  sendVerificationCode,
  verifyVerificationCode,
} from 'api/verification/email';
import { isValidEmail } from 'common/util';
import { useCountdown } from 'hooks/useCountDown';
import { useState } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useApplyFormStore from 'state/apply-form';

export default function EmailVerification({
  form,
  setIsEmailVerified,
  isEmailVerified,
}) {
  const { formData } = useApplyFormStore();

  const {
    register,
    formState: { errors },
    watch,
  } = form;

  const email = watch('email');

  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [otp, setOtp] = useState();

  const [countdown, startCountdown] = useCountdown(60);

  const sendVerificationEmailHandler = async () => {
    if (!email) {
      toast.error('Email is required for sending verification code!');
      return;
    }

    if (!isValidEmail(email)) {
      toast.error('Enter a valid email address');
      return;
    }

    setIsSending(true);

    const resp = await sendVerificationCode(email);

    setIsSending(false);

    if (!resp.success) {
      toast.error('Could not send verification code!');
      return;
    }

    toast.success(`Verification code sent successfully to ${email}`);

    setShowOtpInput(true);

    startCountdown();
  };

  const verifyEmailHandler = async () => {
    if (!email || !otp) {
      toast.error('Email and OTP are required for verifying email!');
      return;
    }

    if (!isValidEmail(email)) {
      toast.error('Enter a valid email address');
      return;
    }

    setIsVerifying(true);

    const resp = await verifyVerificationCode(email, otp);

    setIsVerifying(false);

    if (!resp.success) {
      toast.error('Could not verify email!');
      return;
    }

    setIsEmailVerified(true);

    toast.success('Email verified successfully!');
  };

  return (
    <div className="flex flex-col pb-10 pt-8 gap-4">
      <div className="self-stretch p-10 pb-0 flex-col justify-center items-start gap-10 flex">
        <div className="text-[#434343] text-xl font-medium font-['Inter'] leading-[25px]">
          Verify your email - you can use it to create and manage your
          application
        </div>
        <div className="self-stretch flex-col sm:flex-row justify-start items-end gap-5 flex">
          <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
              Email
            </label>
            <input
              placeholder="Email"
              type="email"
              {...register('email', {
                required: 'Email is required!',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email address',
                },
              })}
              defaultValue={formData.email}
              className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 justify-start items-center gap-3 inline-flex"
            />
          </div>
          <div className="grow w-full shrink basis-0 h-12 flex-col justify-between items-end inline-flex">
            <div className="self-stretch h-12 bg-[#fabf01] rounded-lg justify-center items-center gap-2.5 inline-flex">
              <button
                className="text-center w-full h-full text-[#085454] text-base font-bold font-['Inter'] uppercase leading-[17.60px]"
                onClick={sendVerificationEmailHandler}
                type="button"
                disabled={countdown > 0 || isSending}
                style={{ opacity: countdown > 0 || isSending ? 0.5 : 1 }}
              >
                {isSending ? (
                  <div className="flex items-center justify-center gap-2">
                    <ClipLoader size={20} /> <p>Sending OTP...</p>
                  </div>
                ) : countdown > 0 ? (
                  'Retry in ' + countdown + ' ' + 'seconds...'
                ) : (
                  'Get OTP'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showOtpInput && (
        <div className="self-stretch p-10 pb-0 flex-col justify-center items-start gap-10 flex">
          <div className="self-stretch flex-col sm:flex-row justify-start items-end gap-5 inline-flex">
            <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
              <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
                Verification Code
              </label>
              <div className="flex flex-row w-full gap-2">
                <input
                  placeholder="Enter verification code"
                  type="text"
                  value={otp}
                  autoFocus
                  onChange={(e) => setOtp(e.target.value)}
                  className="self-stretch grow h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 justify-start items-center gap-3 inline-flex"
                />
              </div>
            </div>
            <div className="grow w-full shrink basis-0 h-12 flex-col justify-between items-end inline-flex">
              <div className="self-stretch h-12 bg-white rounded-lg justify-center items-center gap-2.5 inline-flex">
                <button
                  className="text-center w-full grow h-full rounded-lg border border-[#085454] text-[#085454] text-base font-bold font-['Inter'] uppercase leading-[17.60px]"
                  onClick={verifyEmailHandler}
                  type="button"
                  disabled={isVerifying || isEmailVerified}
                >
                  {isEmailVerified ? (
                    <div className="flex items-center justify-center gap-2">
                      <CheckCircleFill className="w-4 h-4" color="#085454" />
                      <p>Verified</p>
                    </div>
                  ) : isVerifying ? (
                    <div className="flex items-center justify-center gap-2">
                      <ClipLoader size={20} /> <p>Verifying...</p>
                    </div>
                  ) : (
                    'Verify'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {errors.email && (
        <div className="px-10">
          <p className="text-red-600 text-sm">{errors.email.message}</p>
        </div>
      )}
      {errors.otp && (
        <div className="px-10">
          <p className="text-red-600 text-sm">{errors.otp.message}</p>
        </div>
      )}
    </div>
  );
}
