export const steps = [
  {
    title: 'Email Verification and Consent',
    content: 'EmailForm',
  },
  {
    title: 'Personal Information',
    content: 'PersonalInfoForm',
  },
  {
    title: 'Contact Information',
    content: 'ContactInfoForm',
  },
  {
    title: 'Past Work Experience',
    content: 'PastWorkForm',
  },
  {
    title: 'Application Questions',
    content: 'ApplicationQuestions',
  },
  {
    title: 'Review and Submit',
    content: 'ReviewAndSubmit',
  },
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const languageOptions = [
  'English',
  'Dutch',
  'French',
  'German',
  'Spanish',
  'Danish',
];

export const fluencyOptions = [
  { value: 'Spoken', label: 'Spoken' },
  { value: 'Written', label: 'Written' },
];

export const channelOptions = [
  { value: 'Email', label: 'Email' },
  { value: 'Chat', label: 'Chat' },
  { value: 'Voice', label: 'Voice' },
  { value: 'Social Media Suite', label: 'Social Media Suite' },
];

export const tools = [
  { value: 'Gorgias', label: 'Gorgias' },
  { value: 'Zendesk', label: 'Zendesk' },
  { value: 'Freshdesk', label: 'Freshdesk' },
  { value: 'Salesforce', label: 'Salesforce' },
  { value: 'Zoho Desk', label: 'Zoho Desk' },
  { value: 'LiveChat', label: 'LiveChat' },
];
