import useApplyFormStore from 'state/apply-form';

export default function Review() {
  const { formData } = useApplyFormStore();

  return (
    <div className="self-stretch p-10 flex-col justify-center items-start gap-10 flex bg-gray-50 rounded-lg">
      <div className="text-gray-800 text-2xl font-semibold font-['Inter'] leading-relaxed border-b pb-4 w-full">
        Review your application and submit.
      </div>
      <div className="w-full space-y-8">
        <ReviewSection title="Personal Information">
          <ReviewItem
            label="First Name"
            value={formData?.personalInfo?.firstName}
          />
          <ReviewItem
            label="Middle Name"
            value={formData?.personalInfo?.middleName}
          />
          <ReviewItem
            label="Last Name"
            value={formData?.personalInfo?.lastName}
          />
          <ReviewItem
            label="Date of Birth"
            value={new Date(formData?.personalInfo?.dob).toLocaleDateString()}
          />
          <ReviewItem
            label="Profile Picture"
            value={
              formData?.personalInfo?.profilePic?.[0]?.name || 'Not uploaded'
            }
          />
        </ReviewSection>

        <ReviewSection title="Contact Information">
          <ReviewItem label="Email" value={formData?.email} />
          <ReviewItem
            label="Phone Number"
            value={formData?.contactInfo?.wa?.number}
          />
          <ReviewItem
            label="Address"
            value={`${formData?.contactInfo?.address?.line}, ${formData?.contactInfo?.address?.city}, ${formData?.contactInfo?.address?.state}, ${formData?.contactInfo?.address?.country}, ${formData?.contactInfo?.address?.zip}`}
          />
        </ReviewSection>

        <ReviewSection title="Work Experience">
          <ReviewItem
            label="Begin Work"
            value={formData?.workExperience?.beginWork}
          />
          <ReviewItem
            label="Customer Service Experience"
            value={`${formData?.workExperience?.experience} years`}
          />
          <ReviewItem
            label="Remote Customer Service Experience"
            value={`${formData?.workExperience?.remoteExperience} years`}
          />
          <ReviewItem
            label="Resume"
            value={
              formData?.workExperience?.resume?.[0]?.name || 'Not uploaded'
            }
          />
          <ReviewList
            label="Past Employers"
            items={formData?.workExperience?.pastEmployers?.map(
              (employer, index) =>
                `${employer?.employerUrl} (${employer?.startMonth}/${employer?.startYear} - ${employer?.endMonth || 'Present'}/${employer?.endYear || 'Present'})`
            )}
          />
        </ReviewSection>

        <ReviewSection title="Questionnaire">
          <ReviewList
            label="Languages"
            items={formData?.questionnaire?.languages?.map(
              (lang) => `${lang?.language} (${lang?.fluency?.join(', ')})`
            )}
          />
          <ReviewList
            label="Customer Support Channels"
            items={formData?.questionnaire?.channels}
          />
          <ReviewItem
            label="Shopify Experience"
            value={formData?.questionnaire?.shopifyExperience}
          />
          <ReviewList
            label="Shopify Employers"
            items={formData?.questionnaire?.shopifyEmployers}
          />
          <ReviewList
            label="Customer Service Tools"
            items={formData?.questionnaire?.customer_service_tools}
          />
          <ReviewItem
            label="Application Video"
            value={
              formData?.questionnaire?.application_video?.[0]?.name ||
              'Not uploaded'
            }
          />
        </ReviewSection>
      </div>
    </div>
  );
}

const ReviewSection = ({ title, children }) => (
  <div className="flex flex-col bg-white p-6 rounded-lg shadow-sm border border-gray-100">
    <span className="font-semibold text-lg text-gray-700 mb-4 pb-2 border-b">
      {title}
    </span>
    <div className="space-y-3">{children}</div>
  </div>
);

const ReviewItem = ({ label, value }) => (
  <div className="flex items-center py-1 hover:bg-gray-50 rounded px-2 -mx-2">
    <span className="font-medium w-1/3 text-gray-600">{label}:</span>
    <span className="w-2/3 text-gray-800 text-end">{value || 'N/A'}</span>
  </div>
);

const ReviewList = ({ label, items }) => (
  <div className="flex flex-col py-1">
    <span className="font-medium text-gray-600 mb-2">{label}:</span>
    <ul className="list-disc list-inside ml-4 space-y-1">
      {items?.map((item, index) => (
        <li
          key={index}
          className="text-gray-800 hover:bg-gray-50 rounded px-2 -mx-2"
        >
          {item}
        </li>
      ))}
    </ul>
  </div>
);
