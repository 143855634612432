import { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS
import { hasAgentTracker } from 'utils/helpers';
import { checkUserAuthentication } from './api';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import ErrorBoundary from './components/errorBoundary';
import PageNotFound from './components/pageNotFound';
import CopilotPlayground from './copilot';
import CopilotConfigurations from './copilot/configurations';
import CopilotConversations from './copilot/conversations';
import CopilotDashboard from './copilot/dashboard';
import AgentView from './dashboards/pulse/Agent';
import TimeDiary from './dashboards/pulse/TimeDiary';
import CategoryDetails from './dashboards/pulse/categoryDetails';
import PulseHome from './dashboards/pulse/home';
import TicketDetails from './dashboards/pulse/ticketDetails';
import Tickets from './dashboards/pulse/tickets';
import ApplicationForm from './hire/apply';
import FreshdeskConnect from './integration/freshdesk';
import GladlyConnect from './integration/gladly';
import GorgiasConnect from './integration/gorgias';
import IntegrationSuccess from './integration/success';
import ZendeskConnect from './integration/zendesk';
import { useAuthStore } from './state';
import TicketQuery from './ticketQuery';
import { Login, Signup } from './users';
import posthog from './utils/track';

const WIP = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Work In Progress</h1>
        <p className="text-lg">
          This section is under construction. Please check back later.
        </p>
      </div>
    </div>
  );
};

const DashboardLayoutV2 = () => {
  const [isLoading, setIsLoading] = useState(true);

  const auth = useAuthStore();
  const { isLoggedIn, account } = auth;
  useEffect(() => {
    checkUserAuthentication()
      .then((data) => {
        auth.updateState('isLoggedIn', data.isAuthenticated);
        auth.updateState('account', data.user.account);
        auth.updateState('accountType', data.user.account_type);
        auth.updateState('name', data.user.name);
        auth.updateState('email', data.user.email);
        localStorage.setItem('account', data.user.account);
        localStorage.setItem('name', data.user.name);
        localStorage.setItem('email', data.user.email);
        posthog.identify(data.user.email);
        posthog.capture(`Brand_open`);
        posthog.register({
          brandName: data.user.account,
          userName: data.user.name,
          email_id: data.user.email,
          timestamp: new Date().toISOString(),
        });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [auth.token]);

  useEffect(() => {
    const capturePageLeave = () => {
      if (
        document.referrer &&
        !document.referrer.includes(window.location.hostname)
      ) {
        posthog.capture('$pageleave', {
          url: window.location.href,
          referrer: document.referrer,
          timestamp: new Date().toISOString(),
        });
      }
    };

    window.addEventListener('beforeunload', capturePageLeave);

    return () => {
      window.removeEventListener('beforeunload', capturePageLeave);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center">
        <BarLoader className="m-auto" />
      </div>
    );
  }
  const isValeo = account === 'valeo';
  return (
    <ErrorBoundary posthog={posthog}>
      <Routes>
        {/* Always accessible routes */}
        <Route
          path="/login"
          element={
            isLoggedIn ? (
              <Navigate to={isValeo ? '/copilot' : '/pulse'} />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/register" element={<Signup isLoggedIn={isLoggedIn} />} />
        <Route path="/insights" element={<TicketQuery />} />
        <Route path="/chat" element={<CopilotPlayground client="mensa" />} />
        <Route path="/valeo" element={<CopilotPlayground client="valeo" />} />
        <Route path="/connect/gladly" element={<GladlyConnect />} />
        <Route path="/connect/zendesk" element={<ZendeskConnect />} />
        <Route path="/connect/freshdesk" element={<FreshdeskConnect />} />
        <Route path="/connect/gorgias" element={<GorgiasConnect />} />
        <Route path="/connect/success" element={<IntegrationSuccess />} />
        <Route path="/hire/apply/:jobid" element={<ApplicationForm />} />
        <Route
          path="/"
          element={isLoggedIn ? <ProtectedLayout /> : <Navigate to="/login" />}
        >
          <Route
            path="/pulse"
            element={
              auth.account === 'prostylingtools' ||
              auth.account === 'iconnutrition' ||
              auth.account === 'jetmetothevip' ||
              auth.account === 'lamptwist' ? (
                <WIP />
              ) : (
                <PulseHome />
              )
            }
          />
          <Route
            path="/pulse/time-diary/*"
            element={
              hasAgentTracker(auth.account) ? (
                <TimeDiary />
              ) : (
                <Navigate to="/pulse" />
              )
            }
          />
          <Route path="/pulse/:category" element={<CategoryDetails />} />
          <Route path="/pulse/:category/tickets" element={<Tickets />} />
          <Route
            path="/pulse/:category/tickets/:ticketId"
            element={<TicketDetails />}
          />
          <Route
            path="/pulse/:category/:subCategory"
            element={<CategoryDetails />}
          />
          <Route
            path="/pulse/:category/:subCategory/tickets"
            element={<Tickets />}
          />
          <Route
            path="/pulse/:category/:subCategory/tickets/:ticketId"
            element={<TicketDetails />}
          />
          <Route path="/pulse/agent/:id" element={<AgentView />} />
          <Route
            path="/copilot/*"
            element={
              account === 'valeo' ? <Outlet /> : <Navigate to="/login" />
            }
          >
            <Route path="" element={<CopilotDashboard />} />
            <Route path="configure" element={<CopilotConfigurations />} />
            <Route path="conversations" element={<CopilotConversations />} />
          </Route>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to={isValeo ? '/copilot' : '/pulse'} />
              ) : (
                <Login />
              )
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ErrorBoundary>
  );

  function ProtectedLayout() {
    return (
      <div className="h-screen font-inter flex flex-col">
        <Header />
        <div className="flex w-full overflow-hidden h-screenHeight">
          <Sidebar account={account} />
          <div className="overflow-auto h-full flex-1">
            <Outlet />{' '}
            {/* This will render nested routes for pulse or old layout */}
          </div>
        </div>
      </div>
    );
  }
};
export { DashboardLayoutV2 };
