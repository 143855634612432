import { getSubcategoryData } from 'api/agentPerformanceApi';

// Helper function to get the days in a month with starting empty days
export const getMonthDays = (year, month) => {
  const date = new Date(year, month, 1);
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const days = Array.from({ length: daysInMonth }, (_, i) => ({
    num: 0,
    day: i + 1,
  }));
  const emptyEndDays = (7 - ((firstDayOfWeek + days.length) % 7)) % 7;

  return { days, firstDayOfWeek, emptyEndDays };
};

export function extractDateFromFilename(filename) {
  // Regex to match the date-time pattern in the filename
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}-\d{2}-\d{2}-\d{3}Z)\.png$/;
  const match = filename.match(regex);

  if (match) {
    // Replace dashes and colons in the time part to make it compatible with Date constructor
    const dateTimeStr = match[1];
    const year = parseInt(dateTimeStr.slice(0, 4), 10);
    const month = parseInt(dateTimeStr.slice(5, 7), 10) - 1; // JavaScript months are 0-indexed
    const day = parseInt(dateTimeStr.slice(8, 10), 10);
    const hours = parseInt(dateTimeStr.slice(11, 13), 10);
    const minutes = parseInt(dateTimeStr.slice(14, 16), 10);
    const seconds = parseInt(dateTimeStr.slice(17, 19), 10);
    const milliseconds = parseInt(dateTimeStr.slice(20, 23), 10);
    const date = new Date(
      year,
      month,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    );
    const timeZoneOffset = date.getTimezoneOffset() * 60000;

    // Adjust the date to UTC by subtracting the time zone offset
    date.setTime(date.getTime() - timeZoneOffset);

    return date;
  } else {
    throw new Error('Date-time format not found in the filename.');
  }
}
export function formatWorkTime(workTime) {
  // Calculate hours and minutes from workTime in milliseconds
  const minutes = Math.floor((workTime / (1000 * 60)) % 60);
  const hours = Math.floor(workTime / (1000 * 60 * 60));

  // Format hours and minutes to always have two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const exportToCSV = async (
  data,
  agentId,
  account,
  start_date,
  end_date,
  agentName
) => {
  let csvContent = '';
  const headers = [
    'Category',
    'Subcategory',
    'Tickets Closed',
    'Overall',
    'Empathy',
    'Personalization',
    'Responsiveness',
    'Completeness',
    'Emotional Polarity',
  ];

  csvContent += headers.join(',') + '\n';
  for (const category of data) {
    // Highlight category row by adding ** around the category name
    const row = [
      `${category.category}`,
      '', // Empty subcategory for main categories
      `${category.totalTickets}`,
      `${(category.averageCSAT.overall * 20).toFixed(2)}%`,
      `${(category.averageCSAT.empathy * 20).toFixed(2)}%`,
      `${(category.averageCSAT.qualitySupport * 20).toFixed(2)}%`,
      `${(category.averageCSAT.agentResponsive * 20).toFixed(2)}%`,
      `${(category.averageCSAT.resolutionalCompleteness * 20).toFixed(2)}%`,
      `${(category.averageCSAT.emotionalPolarity * 20).toFixed(2)}%`,
    ];

    csvContent += row.join(',') + '\n';

    try {
      const subData = await getSubcategoryData(
        agentId,
        account,
        start_date,
        end_date,
        category.category
      );

      if (subData && subData.length > 0) {
        subData.forEach((subcategory) => {
          const subrow = [
            '', // Empty category for subcategories
            subcategory.subCategory,
            subcategory.totalTickets,
            `${(subcategory.averageCSAT.overall * 20).toFixed(2)}%`,
            `${(subcategory.averageCSAT.empathy * 20).toFixed(2)}%`,
            `${(subcategory.averageCSAT.qualitySupport * 20).toFixed(2)}%`,
            `${(subcategory.averageCSAT.agentResponsive * 20).toFixed(2)}%`,
            `${(subcategory.averageCSAT.resolutionalCompleteness * 20).toFixed(2)}%`,
            `${(subcategory.averageCSAT.emotionalPolarity * 20).toFixed(2)}%`,
          ];
          csvContent += subrow.join(',') + '\n';
        });
      }
    } catch (error) {
      console.error('Error fetching subcategory data:', error);
    }
  }

  // Add agent name and company name at the end of the CSV
  csvContent += '\n';
  csvContent += `Agent Name,${agentName}\n`;
  csvContent += `Company Name,${account}\n`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `agent_performance_${agentName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const hasAgentTracker = (account) => {
  const specialAccounts = [
    'kahawa1893',
    'prostylingtools',
    'drink-trip',
    'iconnutrition',
    'jetmetothevip',
    'lamptwist',
  ];
  return specialAccounts.includes(account);
};
