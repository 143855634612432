import {
  sendVerificationCode,
  verifyVerificationCode,
} from 'api/verification/whatsapp';
import { City, Country, State } from 'country-state-city';
import { useCountdown } from 'hooks/useCountDown';
import { postcodeValidator } from 'postcode-validator';
import { useState } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { PhoneInput } from 'react-international-phone';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import useApplyFormStore from 'state/apply-form';

export default function ContactInfo({ form, setIsWAVerified, isWAVerified }) {
  const { formData } = useApplyFormStore();

  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = form;

  const [phoneNumber, setPhoneNumber] = useState(
    formData.contactInfo.wa.number || ''
  );

  const [isSending, setIsSending] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [countdown, startCountdown] = useCountdown(60);
  const [otp, setOtp] = useState();
  const [showOtpInput, setShowOtpInput] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(
    formData.contactInfo?.address?.country || ''
  );
  const [selectedState, setSelectedState] = useState(
    formData.contactInfo?.address?.state || ''
  );
  const [selectedCity, setSelectedCity] = useState(
    formData.contactInfo?.address?.city || ''
  );

  const sendWAVerificationHandler = async () => {
    if (!phoneNumber) {
      toast.error('Phone number is required for sending verification code!');
      return;
    }

    setIsSending(true);

    const sanitizedNumber = phoneNumber.slice(
      formData.contactInfo.wa.code.length + 1
    );

    const resp = await sendVerificationCode(
      sanitizedNumber,
      `+${formData.contactInfo.wa.code}`
    );

    setIsSending(false);

    if (!resp.success) {
      toast.error('Could not send verification code!');
      return;
    }

    toast.success(`Verification code sent successfully to ${phoneNumber}`);

    startCountdown();
    setShowOtpInput(true);
  };

  const verifyWAVerificationHandler = async () => {
    if (!phoneNumber || !otp) {
      toast.error('Phone number and OTP are required for verifying OTP!');
      return;
    }

    setIsVerifying(true);

    const sanitizedNumber = phoneNumber.slice(
      formData.contactInfo.wa.code.length + 1
    );

    const resp = await verifyVerificationCode(
      sanitizedNumber,
      `+${formData.contactInfo.wa.code}`,
      otp
    );

    setIsVerifying(false);

    if (!resp.success) {
      toast.error('Could not verify WhatsApp!');
      return;
    }

    setIsWAVerified(true);

    toast.success('Whatsapp verified successfully!');
  };

  return (
    <div className="self-stretch p-10 flex-col justify-center items-start gap-10 flex">
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Email
        </label>
        <input
          placeholder="Email"
          type="email"
          readOnly
          value={formData.email}
          className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 justify-start items-center gap-3 inline-flex"
        />
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Phone Number (WhatsApp)
        </label>
        <small className="text-neutral-500">
          By sharing your number, you are consenting to being contacted via
          Whatsapp messages and calls
        </small>
        <div className="flex flex-col sm:flex-row gap-4 items-center w-full">
          <PhoneInput
            onChange={(number, data) => {
              clearErrors('contactInfo.wa.number');
              const countryEnum = data.country.iso2;
              const code = data.country.dialCode;

              const sanitizedNumber = number.slice(code.length + 1);
              const finalNumber = sanitizedNumber
                ? `+${code}${sanitizedNumber}`
                : '';

              setPhoneNumber(number);
              setValue('contactInfo.wa.number', finalNumber);
              setValue('contactInfo.wa.code', code || '');
              setValue('contactInfo.wa.countryEnum', countryEnum || '');
            }}
            defaultCountry={formData.contactInfo?.wa?.countryEnum || 'us'}
            placeholder="Whatsapp Number"
            className="self-stretch grow bg-white p-1 rounded-lg border border-neutral-800"
            value={phoneNumber}
            disableCountryGuess
            inputStyle={{ width: '100%', border: 'none' }}
            countrySelectorStyleProps={{ style: { border: 'none' } }}
          />
          <div className="grow self-stretch h-12 bg-[#fabf01] rounded-lg justify-center items-center gap-2.5 inline-flex">
            <button
              type="button"
              className="text-center w-full h-full text-[#085454] text-base font-bold font-['Inter'] uppercase leading-[17.60px]"
              onClick={sendWAVerificationHandler}
              disabled={countdown > 0 || isSending}
              style={{ opacity: countdown > 0 || isSending ? 0.5 : 1 }}
            >
              {isSending ? (
                <div className="flex items-center justify-center gap-2">
                  <ClipLoader size={20} /> <p>Sending OTP...</p>
                </div>
              ) : countdown > 0 ? (
                'Retry in ' + countdown + ' ' + 'seconds...'
              ) : (
                'Get OTP'
              )}
            </button>
          </div>
        </div>
        {errors.contactInfo?.wa?.number && (
          <p className="text-red-600 text-sm">
            {errors.contactInfo.wa.number.message}
          </p>
        )}
      </div>

      {showOtpInput && (
        <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
            WhatsApp Verification Code
          </label>
          <div className="flex flex-col sm:flex-row gap-4 items-center w-full">
            <input
              placeholder="Enter verification code"
              type="text"
              value={otp}
              autoFocus
              onChange={(e) => setOtp(e.target.value)}
              className="self-stretch grow h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 justify-start items-center gap-3 inline-flex"
            />
            <div className="grow self-stretch h-12 rounded-lg justify-center items-center gap-2.5 inline-flex">
              <button
                className="text-center w-full grow h-full rounded-lg border border-[#085454] text-[#085454] text-base font-bold font-['Inter'] uppercase leading-[17.60px]"
                onClick={verifyWAVerificationHandler}
                type="button"
                disabled={isVerifying || isWAVerified}
              >
                {isWAVerified ? (
                  <div className="flex items-center justify-center gap-2">
                    <CheckCircleFill className="w-4 h-4" color="#085454" />
                    <p>Verified</p>
                  </div>
                ) : isVerifying ? (
                  <div className="flex items-center justify-center gap-2">
                    <ClipLoader size={20} /> <p>Verifying...</p>
                  </div>
                ) : (
                  'Verify'
                )}
              </button>
            </div>
          </div>
          {errors.contactInfo?.wa?.number && (
            <p className="text-red-600 text-sm">
              {errors.contactInfo.wa.number.message}
            </p>
          )}
        </div>
      )}

      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Address Line
        </label>
        <input
          placeholder="Address Line"
          {...register('contactInfo.address.line')}
          defaultValue={formData.contactInfo?.address?.line}
          className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
        />
      </div>
      <div className="grid w-full grid-cols-1 sm:grid-cols-2 gap-6">
        <div className="flex flex-col justify-start items-start gap-2">
          <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
            Country
          </label>
          <select
            {...register('contactInfo.address.country', {
              required: 'Country is required',
            })}
            value={selectedCountry}
            onChange={(e) => {
              setSelectedCountry(e.target.value);
              setSelectedState('');
              setSelectedCity('');
              setValue('contactInfo.address.country', e.target.value);
              setValue('contactInfo.address.state', '');
              setValue('contactInfo.address.city', '');
            }}
            className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
          >
            <option value="">Select Country</option>
            {Country.getAllCountries().map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {errors.contactInfo?.address?.country && (
            <p className="text-red-600 text-sm">
              {errors.contactInfo?.address?.country.message}
            </p>
          )}
        </div>

        <div className="flex flex-col justify-start items-start gap-2">
          <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
            State
          </label>
          <select
            {...register('contactInfo.address.state')}
            value={selectedState}
            onChange={(e) => {
              setSelectedState(e.target.value);
              setSelectedCity('');
              setValue('contactInfo.address.state', e.target.value);
              setValue('contactInfo.address.city', '');
            }}
            disabled={!selectedCountry}
            className={`self-stretch h-12 px-5 py-2 rounded-lg border border-neutral-800 ${
              !selectedCountry
                ? 'opacity-50 cursor-not-allowed bg-gray-100'
                : 'bg-white'
            }`}
          >
            <option value="">Select State</option>
            {State.getStatesOfCountry(selectedCountry).map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="flex flex-col justify-start items-start gap-2">
          <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
            City
          </label>
          <select
            {...register('contactInfo.address.city')}
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              setValue('contactInfo.address.city', e.target.value);
            }}
            disabled={!selectedState}
            className={`self-stretch h-12 px-5 py-2 rounded-lg border border-neutral-800 ${
              !selectedState
                ? 'opacity-50 cursor-not-allowed bg-gray-100'
                : 'bg-white'
            }`}
          >
            <option value="">Select City</option>
            {City.getCitiesOfState(selectedCountry, selectedState).map(
              (city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              )
            )}
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="flex flex-col justify-start items-start gap-2">
          <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
            Zip Code
          </label>
          <input
            placeholder="Zip"
            {...register('contactInfo.address.zip', {
              validate: (value) => {
                if (!selectedCountry || !value) return true;

                try {
                  const isValid = postcodeValidator(value, selectedCountry);
                  return isValid || 'Invalid postal code for selected country';
                } catch (error) {
                  // If country is not supported, skip validation
                  return true;
                }
              },
            })}
            defaultValue={formData.contactInfo?.address?.zip}
            className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
          />
          {errors.contactInfo?.address?.zip && (
            <p className="text-red-600 text-sm">
              {errors.contactInfo.address.zip.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
