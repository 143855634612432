import { CheckCircleFill } from 'react-bootstrap-icons';

export default function Stepper({ steps, currstep }) {
  return steps.map((stepItem, idx) => (
    <>
      <div
        key={stepItem.content}
        className="w-[120px] flex-col justify-center items-center gap-2 inline-flex"
      >
        <div
          className={`w-[25px] h-[25px] bg-white rounded-xl ${currstep > idx ? '' : currstep === idx ? 'border border-neutral-800' : 'border border-neutral-500'} flex-col justify-center items-center gap-2.5 flex`}
        >
          {currstep > idx ? (
            <CheckCircleFill color="#00BA00" width={25} height={25} />
          ) : (
            <div
              className={`text-center ${idx === currstep ? 'text-neutral-800' : 'text-neutral-500'} text-xs font-semibold font-['Inter'] leading-[13.20px]`}
            >
              {idx + 1}
            </div>
          )}
        </div>
        <div
          className={`w-[100px] text-center ${idx === currstep ? 'text-neutral-800' : 'text-neutral-500'} text-xs font-normal font-['Inter'] leading-[13.20px]`}
        >
          {stepItem.title}
        </div>
      </div>
      {idx < steps.length - 1 && (
        <div className="w-[100px] self-center border border-neutral-200"></div>
      )}
    </>
  ));
}
