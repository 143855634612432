const { flattenObject } = require('./util');

export function createFormDataFromObject(obj) {
  const formData = new FormData();
  const flatObj = flattenObject(obj);

  Object.keys(flatObj).forEach((key) => {
    const value = flatObj[key];
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          Object.keys(item).forEach((subKey) => {
            if (Array.isArray(item[subKey])) {
              item[subKey].forEach((subItem) => {
                formData.append(`${key}[${index}][${subKey}][]`, subItem);
              });
            } else {
              formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
            }
          });
        } else {
          formData.append(`${key}[]`, item);
        }
      });
    } else if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (value instanceof Date) {
      formData.append(key, value.toISOString());
    } else if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  });

  return formData;
}
