import html2canvas from 'html2canvas';
import Papa from 'papaparse';
import { colors } from './constant';
export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const capitalizeWords = (str = '') => {
  if (!str) {
    return null;
  }
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
export const downloadDivAsImage = (element, name) => {
  if (element.current) {
    html2canvas(element.current)
      .then((canvas) => {
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${name}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Error while capturing chart:', error));
  }
};

export const estimateCsatLable = (score) => {
  if (score >= 4) {
    return 'excellent';
  } else if (score >= 3 && score < 4) {
    return 'average';
  } else if (score >= 0 && score < 3) {
    return 'poor';
  }
};

export const labelColor = (score) => {
  if (score >= 4) {
    return 'bg-[#C9E6AB]';
  } else if (score >= 3 && score < 4) {
    return 'bg-[#FFE082]';
  } else if (score >= 0 && score < 3) {
    return 'bg-[#E68E98]';
  }
};

export const formatMinutes = (minutes) => {
  if (minutes < 60) {
    return minutes + ' m';
  } else if (minutes < 1440) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return (
      (hours > 0 ? hours + ' h' : '') +
      (remainingMinutes > 0 ? ' ' + remainingMinutes + ' m' : '')
    );
  } else if (minutes < 43200) {
    const days = Math.floor(minutes / 1440);
    const remainingHours = Math.floor((minutes % 1440) / 60);

    return (
      (days > 0 ? days + ' day' + (days !== 1 ? 's' : '') : '') +
      (remainingHours > 0 ? ' ' + remainingHours + ' h' : '')
    );
  } else {
    const months = Math.floor(minutes / 43200);
    const remainingDays = Math.floor((minutes % 43200) / 1440);

    return (
      (months > 0 ? months + ' month' + (months !== 1 ? 's' : '') : '') +
      (remainingDays > 0
        ? ' ' + remainingDays + ' day' + (remainingDays !== 1 ? 's' : '')
        : '')
    );
  }
};

export const getSentiment = (score) => {
  if (score <= 3) {
    return 'Negative';
  } else if (score > 3 && score < 4) {
    return 'Average';
  } else if (score >= 4) {
    return 'Positive';
  } else {
    return '-'; // Handling unexpected input
  }
};

export const hasValue = (value) => {
  return value !== null && value !== undefined;
};

export const DownloadCSV = async (data, type) => {
  const csvData = data.map((item) => ({
    [type]: item.name,
    Tickets: item.count,
    FRT: formatMinutes(Math.floor(item?.averageFirstResolutionTime).toFixed(2)),
    'Resolution Time': formatMinutes(
      Math.round(item?.averageFullResolutionTime)
    ),
    '%Reopen': (item?.averageReopen * 100)?.toFixed(2) + '%',
    'Est. CSAT%':
      (type === 'Category'
        ? (
            (parseFloat((item.total_csat / item.count)?.toFixed(2)) / 5) *
            100
          ).toFixed(2)
        : ((item?.csat_score.toFixed(2) / 5) * 100).toFixed(2)) + '%',
  }));

  const csv = Papa.unparse(csvData);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', 'data.csv');
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatIsoStringToDateTime = (isoString) => {
  const date = new Date(isoString);

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  const dateStr = `${month}/${day}/${year}`;
  const timeStr = `${hours}:${minutesStr} ${ampm}`;
  return `${dateStr} ${timeStr}`;
};

export function flattenObject(obj, prefix = '') {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : '';
    if (
      typeof obj[k] === 'object' &&
      obj[k] !== null &&
      !(obj[k] instanceof File)
    ) {
      Object.assign(acc, flattenObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
}

export const isValidEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
