import {
  channelOptions,
  fluencyOptions,
  languageOptions,
  tools,
} from 'hire/apply/constants';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import Select from 'react-select';
import useApplyFormStore from 'state/apply-form';

export default function Questionnaire({ form }) {
  const {
    register,
    formState: { errors },
    control,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questionnaire.languages',
  });

  const employerFields = useWatch({
    control,
    name: 'workExperience.pastEmployers',
  });

  const { formData } = useApplyFormStore();

  return (
    <div className="self-stretch px-10 py-4 flex-col justify-center items-start gap-10 flex">
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          What languages are you able to provide customer support in?
        </label>
        {fields.map((field, index) => (
          <div key={field.id} className="w-full flex items-center gap-4">
            <Controller
              name={`questionnaire.languages.${index}.language`}
              control={control}
              defaultValue={field.language}
              render={({ field: { onChange, value, ...rest } }) => (
                <Select
                  {...rest}
                  value={value ? { label: value, value } : null}
                  options={languageOptions.map((lang) => ({
                    label: lang,
                    value: lang,
                  }))}
                  onChange={(selectedOption) => onChange(selectedOption.value)}
                  className="w-1/3"
                  classNamePrefix="select"
                  placeholder="Select Language"
                  styles={{
                    container: (base) => ({
                      ...base,
                      flexGrow: 1,
                    }),
                  }}
                />
              )}
            />
            <Controller
              name={`questionnaire.languages.${index}.fluency`}
              control={control}
              defaultValue={field.fluency}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={fluencyOptions.filter((option) =>
                    value.includes(option.value)
                  )}
                  options={fluencyOptions}
                  isMulti
                  onChange={(selectedOptions) =>
                    onChange(selectedOptions.map((option) => option.value))
                  }
                  className="w-1/2"
                  classNamePrefix="select"
                  placeholder="Select Fluency"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  styles={{
                    container: (base) => ({
                      ...base,
                      flexGrow: 1,
                    }),
                  }}
                  components={{
                    Option: ({ children, isSelected, innerProps }) => (
                      <div
                        {...innerProps}
                        className="px-3 py-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => {}}
                          className="h-4 w-4"
                        />
                        {children}
                      </div>
                    ),
                  }}
                />
              )}
            />
            <Trash
              className="text-red-500 cursor-pointer"
              onClick={() => remove(index)}
            />
          </div>
        ))}
        <button
          type="button"
          onClick={() => append({ language: '', fluency: [] })}
          className="flex items-center gap-2 text-[#085454] font-medium"
        >
          <PlusCircle /> Add Language
        </button>
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          What channels of customer support can you work with?
        </label>
        <small className="text-neutral-500">Select all that apply</small>
        <Controller
          name="questionnaire.channels"
          control={control}
          defaultValue={formData.questionnaire?.channels || []}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              {...rest}
              value={channelOptions.filter((option) =>
                value.includes(option.value)
              )}
              options={channelOptions}
              isMulti
              onChange={(selectedOptions) =>
                onChange(selectedOptions.map((option) => option.value))
              }
              className="w-full self-stretch"
              classNamePrefix="select"
              placeholder="Select Channels"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '48px',
                  borderColor: '#262626',
                  borderRadius: '0.5rem',
                }),
              }}
            />
          )}
        />
        {errors.questionnaire?.channels && (
          <p className="text-red-600 text-sm">
            {errors.questionnaire.channels.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          What is your skill level with Shopify?
        </label>
        <Controller
          name="questionnaire.shopifyExperience"
          control={control}
          defaultValue={formData.questionnaire?.shopifyExperience || ''}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              {...rest}
              value={{ label: value, value }}
              options={[
                { label: 'Beginner', value: 'Beginner' },
                { label: 'Advanced', value: 'Advanced' },
                { label: 'Expert', value: 'Expert' },
              ]}
              onChange={(selectedOption) => onChange(selectedOption.value)}
              className="w-full self-stretch"
              classNamePrefix="select"
              placeholder="Select Shopify Experience Level"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '48px',
                  borderColor: '#262626',
                  borderRadius: '0.5rem',
                }),
              }}
            />
          )}
        />
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Please mention which of your past employers used Shopify
        </label>
        <Controller
          name="questionnaire.shopifyEmployers"
          control={control}
          defaultValue={formData.questionnaire?.shopifyEmployers || []}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              {...rest}
              value={employerFields
                ?.filter((employer) => value.includes(employer.employerUrl))
                .map((employer) => ({
                  value: employer.employerUrl,
                  label: employer.employerUrl,
                }))}
              options={employerFields?.map((employer) => ({
                value: employer.employerUrl,
                label: employer.employerUrl,
              }))}
              isMulti
              onChange={(selectedOptions) =>
                onChange(selectedOptions.map((option) => option.value))
              }
              className="w-full self-stretch"
              classNamePrefix="select"
              placeholder="Select Shopify Employers"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '48px',
                  borderColor: '#262626',
                  borderRadius: '0.5rem',
                }),
              }}
            />
          )}
        />
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          What customer service tools have you used before?
        </label>
        <Controller
          name="questionnaire.customer_service_tools"
          control={control}
          defaultValue={formData.questionnaire?.customer_service_tools || []}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              {...rest}
              value={tools.filter((option) => value.includes(option.value))}
              options={tools}
              isMulti
              onChange={(selectedOptions) =>
                onChange(selectedOptions.map((option) => option.value))
              }
              className="w-full self-stretch"
              classNamePrefix="select"
              placeholder="Select Customer Service Tools"
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '48px',
                  borderColor: '#262626',
                  borderRadius: '0.5rem',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
              }}
            />
          )}
        />
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-left text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Upload your application video
        </label>
        <small className="text-neutral-500">
          Accepted formats: MP4, MOV, AVI, WebM. Max file size: 100MB
        </small>
        <Controller
          name="questionnaire.application_video"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) => {
              if (value[0]) {
                const allowedTypes = [
                  'video/mp4',
                  'video/quicktime',
                  'video/x-msvideo',
                  'video/webm',
                ];
                if (!allowedTypes.includes(value[0].type)) {
                  return 'Only MP4, MOV, AVI, and WebM formats are allowed';
                }
                if (value[0].size > 100 * 1024 * 1024) {
                  return 'File size should be 100 MB or less';
                }
              }
              return true;
            },
          }}
          render={({ field: { onChange, value, ...field } }) => (
            <div className="w-full">
              <input
                type="file"
                {...field}
                className="hidden"
                id="video-upload"
                accept=".mp4,.mov,.avi,.webm"
                onChange={(e) => {
                  onChange(e.target.files);
                }}
              />
              <label
                htmlFor="video-upload"
                className="flex items-center justify-between w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 cursor-pointer"
              >
                <span className="text-gray-500">
                  {value && value[0]
                    ? value[0].name
                    : formData.questionnaire?.application_video?.[0]?.name ||
                      'Choose video file'}
                </span>
                <span className="text-[#085454] font-medium">Browse</span>
              </label>
            </div>
          )}
        />
        {errors.questionnaire?.application_video && (
          <p className="text-red-600 text-sm">
            {errors.questionnaire.application_video.message}
          </p>
        )}
      </div>
    </div>
  );
}
