import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import useApplyFormStore from 'state/apply-form';

export default function PersonalInfo({ form }) {
  const {
    register,
    formState: { errors },
    control,
  } = form;

  const { formData } = useApplyFormStore();

  return (
    <div className="self-stretch p-10 flex-col justify-center items-start gap-10 flex">
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col sm:flex-row items-center gap-8 w-full">
          <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
              First Name
            </label>
            <input
              placeholder="First Name"
              {...register('personalInfo.firstName', {
                required: 'First name is required',
              })}
              defaultValue={formData.personalInfo.firstName}
              className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
            />
          </div>
          <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
              Middle Name
            </label>
            <input
              placeholder="Middle Name"
              {...register('personalInfo.middleName')}
              defaultValue={formData.personalInfo.middleName}
              className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
            />
          </div>
        </div>
        {errors.personalInfo?.firstName && (
          <p className="text-red-600 text-sm">
            {errors.personalInfo.firstName.message}
          </p>
        )}
      </div>
      <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Last Name
        </label>
        <input
          placeholder="Last Name"
          {...register('personalInfo.lastName', {
            required: 'Last name is required',
          })}
          defaultValue={formData.personalInfo.lastName}
          className="self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
        />
        {errors.personalInfo?.lastName && (
          <p className="text-red-600 text-sm">
            {errors.personalInfo.lastName.message}
          </p>
        )}
      </div>
      <div className="w-full grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Date of Birth
        </label>
        <Controller
          render={({ field }) => (
            <ReactDatePicker
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              placeholderText="Click to select date"
              shouldCloseOnSelect
              selected={field.value ? new Date(field.value) : null}
              className="w-full self-stretch h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800"
              wrapperClassName="w-full"
              dropdownMode="select"
              showTimeSelect={false}
              onChange={(date) => field.onChange(date)}
              showMonthDropdown
              showYearDropdown
            />
          )}
          name="personalInfo.dob"
          control={control}
          rules={{ required: 'DOB is required' }}
        />
        {errors.personalInfo?.dob && (
          <p className="text-red-600 text-sm">
            {errors.personalInfo.dob.message}
          </p>
        )}
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <label className="text-center text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
          Profile Picture
        </label>
        <Controller
          name="personalInfo.profilePic"
          control={control}
          rules={{
            required: 'Profile pic is required.',
          }}
          render={({ field: { onChange, value, ...field } }) => (
            <div className="w-full">
              <input
                type="file"
                {...field}
                className="hidden"
                id="profile-pic-upload"
                accept="image/*"
                onChange={(e) => {
                  onChange(e.target.files);
                }}
              />
              <label
                htmlFor="profile-pic-upload"
                className="flex gap-4 items-center justify-between w-full h-12 px-5 py-2 bg-white rounded-lg border border-neutral-800 cursor-pointer"
              >
                <span className="text-gray-500">
                  {value && value[0]
                    ? value[0].name
                    : formData.personalInfo?.profilePic?.[0]?.name ||
                      'Choose file'}
                </span>
                <span className="text-[#085454] font-medium">Browse</span>
              </label>
            </div>
          )}
        />
        {errors.personalInfo?.profilePic && (
          <p className="text-red-600 text-sm">
            {errors.personalInfo.profilePic.message}
          </p>
        )}
      </div>
    </div>
  );
}
