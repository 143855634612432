import { create } from 'zustand';

const useApplyFormStore = create((set) => ({
  step: 0,
  isEmailVerified: false,
  isWAVerified: false,
  formData: {
    jobid: '',
    email: '',
    personalInfo: {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      profilePic: undefined,
    },
    contactInfo: {
      wa: {
        number: '',
        code: '',
        countryEnum: '',
      },
      address: {
        line: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
    },
    workExperience: {
      resume: undefined,
      beginWork: '',
      experience: '',
      remoteExperience: '',
      pastEmployers: [],
    },
    questionnaire: {
      languages: [],
      channels: [],
      shopifyExperience: '',
      shopifyEmployers: [],
      customer_service_tools: [],
      application_video: undefined,
    },
  },
  setStep: (step) => set(() => ({ step })),
  setIsEmailVerified: (isEmailVerified) => set(() => ({ isEmailVerified })),
  setIsWAVerified: (isWAVerified) => set(() => ({ isWAVerified })),
  setFormData: (data) =>
    set((state) => ({
      formData: {
        ...state.formData,
        ...data,
      },
    })),
  resetForm: () =>
    set(() => ({
      formData: {
        email: '',
        personalInfo: { firstName: '', lastName: '' },
        contactInfo: { phone: '', address: '' },
      },
      step: 0,
    })),
}));

export default useApplyFormStore;
