import { getJobs } from 'api/getJobs';
import { applyJob } from 'api/jobApplyApi';
import { createFormDataFromObject } from 'common/converters';
import Stepper from 'components/Stepper';
import { Country, State } from 'country-state-city';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import {
  CheckCircleFill,
  ExclamationTriangleFill,
} from 'react-bootstrap-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import 'react-international-phone/style.css';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ContactInfo from '../../components/JobApplyForm/ContactInfo';
import EmailVerification from '../../components/JobApplyForm/EmailVerification';
import PersonalInfo from '../../components/JobApplyForm/PersonalInfo';
import Questionnaire from '../../components/JobApplyForm/Questionnaire';
import Review from '../../components/JobApplyForm/Review';
import WorkExperience from '../../components/JobApplyForm/WorkExperience';
import useFormStore from '../../state/apply-form';
import { steps } from './constants';
import './index.css';

const JobApplicationForm = () => {
  const { jobid } = useParams();
  const posthog = usePostHog();

  const {
    step,
    setStep,
    formData,
    setFormData,
    isEmailVerified,
    setIsEmailVerified,
    isWAVerified,
    setIsWAVerified,
  } = useFormStore();
  const [jobs, setJobs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isJobsLoading, setIsJobsLoading] = useState(false);

  const form = useForm();
  const { register, handleSubmit, setError } = form;

  useEffect(() => {
    const fetchJobs = async () => {
      setIsJobsLoading(true);
      try {
        const jobs = await getJobs();
        setJobs(jobs);
      } catch (error) {
        toast.error('Failed to fetch jobs');
      } finally {
        setIsJobsLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const capturePosthogEvent = (event, data) => {
    try {
      posthog.capture(event, data);
    } catch (err) {
      console.log('Error in posthog capture');
    }
  };

  const isValidJob = jobs.find((val) => val.id === parseInt(jobid));

  const validations = () => {
    if (step === 0 && !isEmailVerified) {
      toast.error('Please verify your email before proceeding');
      return false;
    }

    if (step === 2 && !isWAVerified) {
      toast.error('Please verify your WhatsApp number before proceeding');
      return false;
    }

    return true;
  };

  const next = (data) => {
    if (step === 2 && !data.contactInfo?.wa?.number) {
      setError('contactInfo.wa.number', {
        type: 'manual',
        message: 'Phone number is required',
      });
      return;
    }

    capturePosthogEvent('apply_form_next', {
      step,
      email: data.email,
      whatsapp: data.contactInfo?.wa?.number,
    });

    setFormData(data);

    if (!validations()) return;

    setStep(step + 1);
  };

  const prev = () => {
    capturePosthogEvent('apply_form_prev', {
      step,
      email: formData.email,
      whatsapp: formData.contactInfo?.wa?.number,
    });

    setStep(step - 1);
  };

  const formTransform = (data) => {
    data.personalInfo.dob = data.personalInfo?.dob?.toISOString();

    const countryCode = data.contactInfo.address.country;

    if (countryCode) {
      data.contactInfo.address.country =
        Country.getCountryByCode(countryCode).name;

      const stateCode = data.contactInfo.address.state;

      if (stateCode) {
        data.contactInfo.address.state = State.getStateByCodeAndCountry(
          stateCode,
          countryCode
        ).name;
      }
    }

    return data;
  };

  const onSubmit = async (data) => {
    if (!data.contactInfo?.wa?.number) {
      toast.error('Phone number is required');
      return;
    }

    setFormData(data);

    setIsSubmitting(true);

    try {
      const transformedData = formTransform(data);
      const formData = createFormDataFromObject(transformedData);
      await applyJob(formData);
      setStep(step + 1);

      capturePosthogEvent('apply_form_submit', {
        email: transformedData.email,
        whatsapp: transformedData.contactInfo?.wa?.number,
      });
    } catch (err) {
      toast.error(err.message);

      capturePosthogEvent('apply_form_submit_error', {
        email: data.email,
        whatsapp: data.contactInfo?.wa?.number,
        error: err.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <EmailVerification
            form={form}
            setIsEmailVerified={setIsEmailVerified}
            isEmailVerified={isEmailVerified}
          />
        );
      case 1:
        return <PersonalInfo form={form} />;
      case 2:
        return (
          <ContactInfo
            form={form}
            setIsWAVerified={setIsWAVerified}
            isWAVerified={isWAVerified}
          />
        );
      case 3:
        return <WorkExperience form={form} />;
      case 4:
        return <Questionnaire form={form} />;
      case 5:
        return <Review />;
      default:
        return null;
    }
  };

  if (isJobsLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color="#00BA00" size={48} />
      </div>
    );
  }

  if (!isValidJob) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="bg-white flex flex-col items-center  p-8 rounded-lg shadow-md text-center">
          <ExclamationTriangleFill className="text-yellow-500 mb-6" size={64} />
          <h2 className="text-2xl font-bold mb-4 text-gray-800">
            Job Not Found
          </h2>
          <p className="text-gray-600 mb-6">
            We couldn't find the job you're looking for. Please check the job ID
            and try again.
          </p>
        </div>
      </div>
    );
  }

  if (step < steps.length) {
    return (
      <div
        className="w-full mx-auto bg-white flex flex-col h-screen border border-gray-200 rounded-12"
        style={{ borderRadius: 8 }}
      >
        <div
          className="self-stretch p-10 bg-[#f9f9f9] flex-col justify-start items-start gap-5 flex"
          style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
        >
          <div className="text-[#938f8f] text-xl font-normal font-['Inter'] leading-[25px]">
            You're Applying For:{' '}
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-10 flex">
            <div className="self-stretch text-[#434343] text-3xl font-semibold font-['Inter'] capitalize leading-[33px]">
              {isValidJob.title}
            </div>
          </div>
        </div>
        <div className="self-stretch px-4 sm:px-10 sm:py-4 max-w-[90%] sm:max-w-none mx-auto sm:mx-0 flex-shrink-0 overflow-auto no-scrollbar py-4 justify-between items-start inline-flex">
          <Stepper steps={steps} currstep={step} />
        </div>

        <form
          onSubmit={handleSubmit(next)}
          className="flex flex-col flex-grow overflow-hidden"
          style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
        >
          <input type="hidden" {...register('jobid')} value={jobid} />
          <div
            className="flex-grow overflow-y-auto px-2"
            style={{ paddingTop: 16 }}
          >
            {renderStep()}
          </div>

          <div className="sticky bottom-0 left-0 right-0 flex px-10 justify-between py-4 bg-white border-t border-gray-200">
            {step > 0 && (
              <button
                type="button"
                onClick={prev}
                className="px-4 py-2 border border-[#085454] text-[#085454] bg-white font-bold text-base font-['Inter'] rounded-md"
              >
                Previous
              </button>
            )}

            {step < steps.length - 1 && (
              <button
                type="submit"
                className="ml-auto bg-[#085454] text-white font-bold text-base font-['Inter'] px-8 py-2 rounded-md"
              >
                Next
              </button>
            )}

            {step === steps.length - 1 && (
              <button
                type="button"
                onClick={handleSubmit(onSubmit)}
                disabled={isSubmitting}
                className={`ml-auto px-8 py-2 bg-[#fabf01] uppercase text-[#085454] text-base font-bold font-['Inter'] rounded-md ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <ClipLoader size={20} /> <p>Submitting...</p>
                  </div>
                ) : (
                  'Submit'
                )}
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }

  return <ThankYouComponent title={isValidJob.title} email={formData.email} />;
};

const ThankYouComponent = ({ title, email }) => (
  <div className="self-stretch flex-col px-6 py-12 justify-center items-center gap-10 flex h-screen">
    <CheckCircleFill color="#00BA00" size={64} />
    <div className="text-[#434343] text-3xl font-semibold font-['Inter'] leading-[33px] text-center">
      You have successfully applied for {title}
    </div>
    <div className="text-[#434343] text-lg font-normal font-['Inter'] leading-[22px] text-center">
      Please check your email {email} for next steps
    </div>
  </div>
);

export default JobApplicationForm;
