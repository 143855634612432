import { endOfDay, format, startOfDay, subDays, subMonths } from 'date-fns';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useAuthStore } from '../state';
import withOutsideClick from './withOutsideClick';

const formatDate = (date) => format(date, 'dd MMM, yy');

const DateRangeComponent = ({ pageName, isOpen, toggleVisibility }) => {
  const { updateDateSelection, dateSelectionData, accountType } = useAuthStore();
  const posthog = usePostHog();
  const [calendarViewState, setCalendarViewState] = useState('initial'); // 'initial' or 'updated'
  const [dataObj, setDateObj] = useState({
    key: 'selection',
    startDate: dateSelectionData?.range?.startDate || subDays(new Date(), 8),
    endDate: dateSelectionData?.range?.endDate || subDays(new Date(), 2),
  });

  const dateUpdationCall = (data) => {
    setDateObj({
      key: 'selection',
      startDate: data.range.startDate,
      endDate: data.range.endDate,
    });
    updateDateSelection(data);
    setCalendarViewState('initial');
    toggleVisibility();
    posthog.capture(`${pageName}-view_date_range-selection`);
  };

  const isDateSame = (date1, date2) => date1.toDateString() === date2.toDateString();

  const handleSelection = ({ selection }) => {
    const formattedStartDate = startOfDay(selection.startDate);
    const adjustedEndDate = endOfDay(selection.endDate);
    if (calendarViewState === 'initial' && isDateSame(selection.startDate, selection.endDate)) {
      setCalendarViewState('updated');
      setDateObj({
        key: 'selection',
        startDate: formattedStartDate,
        endDate: adjustedEndDate,
      });
    } else {
      dateUpdationCall({
        selection: 'selection',
        range: {
          startDate: formattedStartDate,
          endDate: adjustedEndDate,
        },
      });
    }
  };

  return (
    <div className="relative z-10 flex items-center">
      {dataObj && (
        <div className="grid grid-cols-2 gap-x-2">
          <p className="text-sm font-semibold">From</p>
          <p className="text-sm font-semibold">To</p>
          <p className="text-sm">{formatDate(dataObj.startDate)}</p>
          <p className="text-sm">{formatDate(dataObj.endDate)}</p>
        </div>
      )}
      <button onClick={toggleVisibility} className="cursor-pointer px-4 py-2 bg-white rounded-full flex items-center justify-between flex-1">
        <img src="/fi-2278049.svg" alt="Calendar icon" className="h-5 w-5" />
      </button>
      {isOpen && (
        <div className="absolute top-full mt-2 w-auto right-0">
          <DateRangePicker
            onChange={handleSelection}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={[dataObj]}
            maxDate={subDays(new Date(), accountType == "kimcrm" ? 0 : 1)}
            minDate={subMonths(new Date(), 3)}
            editableDateInputs={true}
          />
        </div>
      )}
    </div>
  );
};

const DateRange = withOutsideClick(DateRangeComponent);

export default DateRange;
